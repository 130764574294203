//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageHeader from "@/components/page-header";
import HeaderFormItem from "@/components/page-header/header-form-item";

export default {
    components: {HeaderFormItem, PageHeader},
    inject: ["hideLoading", "showLoading"],
    computed: {
        dictList() {
            return this.$store.state["dict"].dictList;
        },
        total() {
            return this.$store.state["dict"].total;
        }
    },
    data() {
        return {
            queryParams: {
                name: "",
                type: "",
                state: "",
                sysDicFlag: 1,
                levelFlag: "",
                pageSize: 10,
                pageNum: 1
            },
            deleteIds: []
        };
    },
    created() {
        this.getList();
    },
    methods: {
        //顶部查询
        search() {
            this.queryParams.pageNum = 1;
            this.getList();
        },

        //查询相关信息
        async getList() {
            this.showLoading();
            let res = await this.$store.dispatch('dict/getDictList', this.queryParams);
            this.hideLoading();
            let type = 'error';
            if (res.code === 200) {
                type = 'success';
            }
            this.$notify({
                title: '提示',
                message: res.msg,
                type: type,
                duration: 2000,
                position: "bottom-right"
            });
        },

        //增加字典数据
        addHandler(row) {
            this.$store.dispatch("dict/changeActiveItem", row ? row : {});
            this.$emit("showAdd", true);
        },

        //修改字典数据
        modifyHandler(item) {
            this.$store.dispatch("dict/changeActiveItem", item);
            this.$emit("showModify", true);
        },

        //遍历选中需删除的所有id
        setCodes(res) {
            if (res && res.length > 0) {
                res.forEach(item => {
                    this.deleteIds.push(item.id);
                    this.setCodes(item.childList)
                });
            }
        },

        //删除字典数据
        removeMenuItem(item) {
            this.deleteIds = [];
            this.deleteIds.push(item.id);
            if (item.childList && item.childList.length > 0) {
                this.setCodes(item.childList);
            }
            this.$confirm(`是否删除该字典(${item.name})?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "error",
            }).then(async () => {
                this.showLoading();
                let changeRes = await this.$store.dispatch("dict/removeDictInformation", this.deleteIds);
                this.hideLoading();
                let type = 'error';
                if (changeRes.code === 200) {
                    type = 'success';
                    this.search();
                }
                this.$notify({
                    title: "删除提示",
                    message: changeRes.msg,
                    type: type,
                    duration: 2000,
                    position: "bottom-right"
                });
            }).catch(() => {
            });
        },

        //选择每一页的数据量
        handleSizeChange(val) {
            this.queryParams.pageSize = val;
            this.queryParams.pageNum = 1;
            this.getList();
        },

        //选择当前页页码
        handleCurrentChange(val) {
            this.queryParams.pageNum = val;
            this.getList();
        }
    }
};
