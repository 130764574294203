//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            addForm: {
                name: "",
                value: '',
                type: '',
                description: '',
                sort: 10,
                sysDicFlag: 1,
                state: 0,
                remarks: '',
                parentId: 0,
                levelFlag: 1,
                childList: [],
                id: ''
            },
            rules: {
                name: [
                    {required: true, message: '请输入字典名称'}
                ],
                value: [
                    {required: true, message: '请输入字典值'}
                ],
                type: [
                    {required: true, message: '请输入字典类型'}
                ]
            },
            parentMenuList: []
        };
    },
    computed: {
        activeItem() {
            return this.$store.state['dict'].activeItem;
        }
    },
    inject: ['showLoading', 'hideLoading'],
    async created() {
        let list = [];
        list.push(this.activeItem);
        this.parentMenuList = list;
        for (let i in this.addForm) {
            this.addForm[i] = this.activeItem[i];
        }
    },
    methods: {
        goList() {
            this.$emit('hideModify', false);
        },

        //提交修改的字典数据
        submitForm() {
            this.$refs["addForm"].validate(async (valid) => {
                if (valid) {
                    this.showLoading();
                    let addRes = await this.$store.dispatch("dict/modifyDictInformation", this.addForm);
                    this.hideLoading();
                    let type = 'error';
                    if (addRes.code === 200) {
                        type = 'success';
                        this.$parent.$parent.$refs.list.search();
                        this.goList();
                    }
                    this.$notify({
                        title: '提示',
                        message: addRes.msg,
                        type: type,
                        duration: 2000,
                        position: "bottom-right"
                    });
                }
            });
        },
        resetForm() {
            this.$refs["addForm"].resetFields();
        }
    }
}
