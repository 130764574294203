//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import List from "./list";
import Add from "./add";
import Modify from "./modify";
export default {
	components:{
		List,
        Add,
		Modify
	},
    data(){
        return{
            modifyVisible: false,
            addVisible:false
        }
    },
    methods:{
        handlerModify(bool){
            this.modifyVisible = bool;
        },

        handlerAdd(bool){
            this.addVisible = bool;
        },
    }
}
